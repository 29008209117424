/*	==========================================================================
		Form
	========================================================================== */

	form { padding: 40px 0 60px;
		fieldset { border: 0; margin: 0;
			form .radio-field p,
			label {
				margin: 0;
				display: block;
				font-size: 18px;
				font-weight: 400;
				font-family: Quicksand, sans-serif;;
				text-transform: uppercase;
				color: #666;
			}
			input {
				font-family: Quicksand, sans-serif;
				&[type=text],
				&[type=date],
				&[type=email] {
					display: block;
					width: 100%;
					line-height: 42px;
					height: 42px;
					padding: 0 10px;
					color: #666;
					border: 1px solid #ddd;
					border-radius: 2px;
					transition: border 100ms ease-in-out;
				}
			}
			textarea {
				font-family: Quicksand, sans-serif;
				display: block;
				width: 100%;
				padding: 5px 10px;
				height: 250px;
				line-height: 24px;
				color: #666;
				border: 1px solid #ddd;
				border-radius: 2px;
				transition: border 100ms ease-in-out;
				&:focus {
					border-color: #1e824c;
					outline: none;
				}
			}

			/**
				Fields
			*/
			.submit-field,
			.input-field,
			.radio-field {
				padding: 15px 5px;
				position: relative;
			}

			.input-field {
				label { margin: 0 0 30px; }
				input:focus {
					border-color: #1e824c;
					outline: none;
				}
			}
			.radio-field {
				p { margin-bottom: 10px; }
				label { display: inline-block; margin: 0 20px 0 0; vertical-align: middle; }
				input { display: inline-block; margin: 0 0 0 0; opacity: 0; }
			}

			.radio-field {
				label {
					&:before { content: ''; display: inline-block; background: #999; width: 16px; height: 16px; border-radius: 100%; vertical-align: middle; transition: background 250ms ease-in-out; }
					&.checked:before,
					&.selected:before { background: #ff4200; animation: blip 0.5s; }
				}
			}

			.checkbox-field { padding: 10px 0;
				label { display: inline-block; vertical-align: middle;  }
				input { display: inline-block; vertical-align: middle; margin: 0 10px 2px 5px; }
			}

			.info-field { color: #999; vertical-align: bottom; margin-bottom: 22px; text-align: center; position: relative; z-index: 2;
				i { cursor: pointer; }
				.info-box { display: none; background: #333; border-radius: 2px; position: absolute; right: 50%; bottom: 30px; margin-left: 20px; width: 150px; padding: 10px 10px; margin-right: -14px;
					&:before { content: '▼'; font-family: 'Times'; position: absolute; right: 10px; bottom: -10px; margin-top: -6px; color: #333; }
					p { color: #fff; margin: 0; padding: 0; line-height: 18px; font-weight: 600; font-size: 12px; font-family: Arial, sans-serif; }
				}
				&:hover {
					.info-box { display: block; }
				}
			}

			.disclaimer { margin-left: 20px; color: #666;
				&:before { content: '*'; font-weight: 600; margin-left: -20px; display: inline-block; width: 20px; text-align: center; }
			}

			/**
				Default
			*/
			[type=submit] { padding: 20px 100px; border: 1px solid #1e824c; background: #1e824c; color: #fff; font-weight: 300; font-family: Quicksand, sans-serif; transition: all 150ms ease-in-out;
				&:hover { background: #fff; color: #1e824c; }
			}
			label {
				em { padding: 0 4px; font-style: normal; color: #000; }
			}
			input {
				&[name=licenseplate] { width: 100px; }
			}

			.error-field { position: relative;
				span { display: block; background: #222; line-height: 18px; font-weight: 600; font-size: 12px; text-align: center; color: #fff; border-radius: 2px; position: absolute; right: 30px; bottom: 0; margin-left: 20px; min-width: 120px; padding: 10px 10px;
					&:after { content: '◣'; position: absolute; left: 100%; bottom: 5px; margin-left: -2px; font-family: 'Times',sans-serif; color: #222; }
				}
			}
		}
		.LV_validation_message { position: absolute; bottom: 57px; right: 5px; font-weight: 400; font-family: Arial, sans-serif; color: #666; }
		.LV_validation_message.LV_invalid i { color: #ff4200; }
	}


	@keyframes blip {
		0% 		{ background: #666; }
		50% 	{ transform: scale(1.05); }
		100% 	{ background: #ff4200; transform: scale(1); }
	}
