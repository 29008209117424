@charset "UTF-8";

/*	==========================================

		mobile first:
			col-x: mobile
			col-n-x: tablet
			col-m-x: laptop
			col-l-x: desktop

		desktop first:
			col-s-x: mobile
			col-m-x: tablet
			col-x: laptop
			col-l-x: desktop

	==========================================*/

	$mobile-first: false;

	$class-slug: col !default;
	$columns: 24;

	$screen-query-desktop: 1680px;	//WSXGA+ > 4K
	$screen-query-laptop: 1280px;	//WXGA	 > WSXGA+
	$screen-query-tablet: 640px;	//VGA	 > WXGA

	$screen-width-desktop: 1440px;
	$screen-width-laptop: 1200px;
	$screen-width-tablet: 100%;

	* { box-sizing: border-box; }
	html, body { margin: 0; padding: 0; text-align: center; }

	.row {
		margin: 0 auto;
		text-align: left;
		font-size: 0;
		[class*="col-"] {
			vertical-align: top;
			padding: 0;
			font-size: 1rem;
			position: relative;
			display: inline-block;
		}
	}

	@if $mobile-first == true {
		@for $i from 0 through $columns {
			$column-width: 100/$columns;
			.#{$class-slug}-#{$i} { width: $column-width * $i + %; }
			.ls-#{$i} { margin-left: $column-width * $i + %; }
			.rs-#{$i} { margin-right: $column-width * $i + %; }
		}
		@media (min-width: $screen-query-tablet) {
			.row {
				width: $screen-width-tablet;
				.row {
					width: auto;
				}
			}
			@for $i from 0 through $columns {
				$column-width: 100/$columns;
				.#{$class-slug}-n-#{$i} { width: $column-width * $i + %; }
				.ls-n-#{$i} { margin-left: $column-width * $i + %; }
				.rs-n-#{$i} { margin-right: $column-width * $i + %; }
			}
		}
		@media (min-width: $screen-query-laptop) {
			.row {
				width: $screen-width-laptop;
				.row {
					width: auto;
				}
			}
			@for $i from 0 through $columns {
				$column-width: 100/$columns;
				.#{$class-slug}-m-#{$i} { width: $column-width * $i + %; }
				.ls-m-#{$i} { margin-left: $column-width * $i + %; }
				.rs-m-#{$i} { margin-right: $column-width * $i + %; }
			}
		}
		@media (min-width: $screen-query-desktop) {
			.row {
				width: $screen-width-desktop;
				.row {
					width: auto;
				}
			}
			@for $i from 0 through $columns {
				$column-width: 100/$columns;
				.#{$class-slug}-l-#{$i} { width: $column-width * $i + %; }
				.ls-l-#{$i} { margin-left: $column-width * $i + %; }
				.rs-r-#{$i} { margin-right: $column-width * $i + %; }
			}
		}
	} @else {
		.row {
			width: $screen-width-desktop;
			.row {
				width: auto;
			}
		}
		@for $i from 0 through $columns {
			$column-width: 100/$columns;
			.#{$class-slug}-#{$i} { width: $column-width * $i + %; }
			.ls-#{$i} { margin-left: $column-width * $i + %; }
			.rs-#{$i} { margin-right: $column-width * $i + %; }
		}
		@media (max-width: $screen-query-desktop) {
			.row {
				width: $screen-width-laptop;
				.row {
					width: auto;
				}
			}
			@for $i from 0 through $columns {
				$column-width: 100/$columns;
				.#{$class-slug}-n-#{$i} { width: $column-width * $i + %; }
				.ls-n-#{$i} { margin-left: $column-width * $i + %; }
				.rs-n-#{$i} { margin-right: $column-width * $i + %; }
			}
		}
		@media (max-width: $screen-query-laptop) {
			.row {
				width: $screen-width-tablet;
				.row {
					width: auto;
				}
			}
			@for $i from 0 through $columns {
				$column-width: 100/$columns;
				.#{$class-slug}-m-#{$i} { width: $column-width * $i + %; }
				.ls-m-#{$i} { margin-left: $column-width * $i + %; }
				.rs-m-#{$i} { margin-right: $column-width * $i + %; }
			}
		}
		@media (max-width: $screen-query-tablet) {
			.row {
				width: auto;
				.row {
					width: auto;
				}
			}
			@for $i from 0 through $columns {
				$column-width: 100/$columns;
				.#{$class-slug}-s-#{$i} { width: $column-width * $i + %; }
				.ls-s-#{$i} { margin-left: $column-width * $i + %; }
				.rs-s-#{$i} { margin-right: $column-width * $i + %; }
			}
		}
	}
