@charset "UTF-8";
/*	==========================================================================

		Bite The Lemon
		Author		: Bite The Lemon / Roald Joosen
		E-mail		: roald@bitethelemon.nl
		Website 	: www.bitethelemon.nl

	========================================================================== */
@import url(../vendor/fontello/1.01/css/fontello.css);
/*	==========================================

		mobile first:
			col-x: mobile
			col-n-x: tablet
			col-m-x: laptop
			col-l-x: desktop

		desktop first:
			col-s-x: mobile
			col-m-x: tablet
			col-x: laptop
			col-l-x: desktop

	==========================================*/
* {
  box-sizing: border-box; }

html, body {
  margin: 0;
  padding: 0;
  text-align: center; }

.row {
  margin: 0 auto;
  text-align: left;
  font-size: 0; }
  .row [class*="col-"] {
    vertical-align: top;
    padding: 0;
    font-size: 1rem;
    position: relative;
    display: inline-block; }

.row {
  width: 1440px; }
  .row .row {
    width: auto; }

.col-0 {
  width: 0%; }

.ls-0 {
  margin-left: 0%; }

.rs-0 {
  margin-right: 0%; }

.col-1 {
  width: 4.16667%; }

.ls-1 {
  margin-left: 4.16667%; }

.rs-1 {
  margin-right: 4.16667%; }

.col-2 {
  width: 8.33333%; }

.ls-2 {
  margin-left: 8.33333%; }

.rs-2 {
  margin-right: 8.33333%; }

.col-3 {
  width: 12.5%; }

.ls-3 {
  margin-left: 12.5%; }

.rs-3 {
  margin-right: 12.5%; }

.col-4 {
  width: 16.66667%; }

.ls-4 {
  margin-left: 16.66667%; }

.rs-4 {
  margin-right: 16.66667%; }

.col-5 {
  width: 20.83333%; }

.ls-5 {
  margin-left: 20.83333%; }

.rs-5 {
  margin-right: 20.83333%; }

.col-6 {
  width: 25%; }

.ls-6 {
  margin-left: 25%; }

.rs-6 {
  margin-right: 25%; }

.col-7 {
  width: 29.16667%; }

.ls-7 {
  margin-left: 29.16667%; }

.rs-7 {
  margin-right: 29.16667%; }

.col-8 {
  width: 33.33333%; }

.ls-8 {
  margin-left: 33.33333%; }

.rs-8 {
  margin-right: 33.33333%; }

.col-9 {
  width: 37.5%; }

.ls-9 {
  margin-left: 37.5%; }

.rs-9 {
  margin-right: 37.5%; }

.col-10 {
  width: 41.66667%; }

.ls-10 {
  margin-left: 41.66667%; }

.rs-10 {
  margin-right: 41.66667%; }

.col-11 {
  width: 45.83333%; }

.ls-11 {
  margin-left: 45.83333%; }

.rs-11 {
  margin-right: 45.83333%; }

.col-12 {
  width: 50%; }

.ls-12 {
  margin-left: 50%; }

.rs-12 {
  margin-right: 50%; }

.col-13 {
  width: 54.16667%; }

.ls-13 {
  margin-left: 54.16667%; }

.rs-13 {
  margin-right: 54.16667%; }

.col-14 {
  width: 58.33333%; }

.ls-14 {
  margin-left: 58.33333%; }

.rs-14 {
  margin-right: 58.33333%; }

.col-15 {
  width: 62.5%; }

.ls-15 {
  margin-left: 62.5%; }

.rs-15 {
  margin-right: 62.5%; }

.col-16 {
  width: 66.66667%; }

.ls-16 {
  margin-left: 66.66667%; }

.rs-16 {
  margin-right: 66.66667%; }

.col-17 {
  width: 70.83333%; }

.ls-17 {
  margin-left: 70.83333%; }

.rs-17 {
  margin-right: 70.83333%; }

.col-18 {
  width: 75%; }

.ls-18 {
  margin-left: 75%; }

.rs-18 {
  margin-right: 75%; }

.col-19 {
  width: 79.16667%; }

.ls-19 {
  margin-left: 79.16667%; }

.rs-19 {
  margin-right: 79.16667%; }

.col-20 {
  width: 83.33333%; }

.ls-20 {
  margin-left: 83.33333%; }

.rs-20 {
  margin-right: 83.33333%; }

.col-21 {
  width: 87.5%; }

.ls-21 {
  margin-left: 87.5%; }

.rs-21 {
  margin-right: 87.5%; }

.col-22 {
  width: 91.66667%; }

.ls-22 {
  margin-left: 91.66667%; }

.rs-22 {
  margin-right: 91.66667%; }

.col-23 {
  width: 95.83333%; }

.ls-23 {
  margin-left: 95.83333%; }

.rs-23 {
  margin-right: 95.83333%; }

.col-24 {
  width: 100%; }

.ls-24 {
  margin-left: 100%; }

.rs-24 {
  margin-right: 100%; }

@media (max-width: 1680px) {
  .row {
    width: 1200px; }
    .row .row {
      width: auto; }
  .col-n-0 {
    width: 0%; }
  .ls-n-0 {
    margin-left: 0%; }
  .rs-n-0 {
    margin-right: 0%; }
  .col-n-1 {
    width: 4.16667%; }
  .ls-n-1 {
    margin-left: 4.16667%; }
  .rs-n-1 {
    margin-right: 4.16667%; }
  .col-n-2 {
    width: 8.33333%; }
  .ls-n-2 {
    margin-left: 8.33333%; }
  .rs-n-2 {
    margin-right: 8.33333%; }
  .col-n-3 {
    width: 12.5%; }
  .ls-n-3 {
    margin-left: 12.5%; }
  .rs-n-3 {
    margin-right: 12.5%; }
  .col-n-4 {
    width: 16.66667%; }
  .ls-n-4 {
    margin-left: 16.66667%; }
  .rs-n-4 {
    margin-right: 16.66667%; }
  .col-n-5 {
    width: 20.83333%; }
  .ls-n-5 {
    margin-left: 20.83333%; }
  .rs-n-5 {
    margin-right: 20.83333%; }
  .col-n-6 {
    width: 25%; }
  .ls-n-6 {
    margin-left: 25%; }
  .rs-n-6 {
    margin-right: 25%; }
  .col-n-7 {
    width: 29.16667%; }
  .ls-n-7 {
    margin-left: 29.16667%; }
  .rs-n-7 {
    margin-right: 29.16667%; }
  .col-n-8 {
    width: 33.33333%; }
  .ls-n-8 {
    margin-left: 33.33333%; }
  .rs-n-8 {
    margin-right: 33.33333%; }
  .col-n-9 {
    width: 37.5%; }
  .ls-n-9 {
    margin-left: 37.5%; }
  .rs-n-9 {
    margin-right: 37.5%; }
  .col-n-10 {
    width: 41.66667%; }
  .ls-n-10 {
    margin-left: 41.66667%; }
  .rs-n-10 {
    margin-right: 41.66667%; }
  .col-n-11 {
    width: 45.83333%; }
  .ls-n-11 {
    margin-left: 45.83333%; }
  .rs-n-11 {
    margin-right: 45.83333%; }
  .col-n-12 {
    width: 50%; }
  .ls-n-12 {
    margin-left: 50%; }
  .rs-n-12 {
    margin-right: 50%; }
  .col-n-13 {
    width: 54.16667%; }
  .ls-n-13 {
    margin-left: 54.16667%; }
  .rs-n-13 {
    margin-right: 54.16667%; }
  .col-n-14 {
    width: 58.33333%; }
  .ls-n-14 {
    margin-left: 58.33333%; }
  .rs-n-14 {
    margin-right: 58.33333%; }
  .col-n-15 {
    width: 62.5%; }
  .ls-n-15 {
    margin-left: 62.5%; }
  .rs-n-15 {
    margin-right: 62.5%; }
  .col-n-16 {
    width: 66.66667%; }
  .ls-n-16 {
    margin-left: 66.66667%; }
  .rs-n-16 {
    margin-right: 66.66667%; }
  .col-n-17 {
    width: 70.83333%; }
  .ls-n-17 {
    margin-left: 70.83333%; }
  .rs-n-17 {
    margin-right: 70.83333%; }
  .col-n-18 {
    width: 75%; }
  .ls-n-18 {
    margin-left: 75%; }
  .rs-n-18 {
    margin-right: 75%; }
  .col-n-19 {
    width: 79.16667%; }
  .ls-n-19 {
    margin-left: 79.16667%; }
  .rs-n-19 {
    margin-right: 79.16667%; }
  .col-n-20 {
    width: 83.33333%; }
  .ls-n-20 {
    margin-left: 83.33333%; }
  .rs-n-20 {
    margin-right: 83.33333%; }
  .col-n-21 {
    width: 87.5%; }
  .ls-n-21 {
    margin-left: 87.5%; }
  .rs-n-21 {
    margin-right: 87.5%; }
  .col-n-22 {
    width: 91.66667%; }
  .ls-n-22 {
    margin-left: 91.66667%; }
  .rs-n-22 {
    margin-right: 91.66667%; }
  .col-n-23 {
    width: 95.83333%; }
  .ls-n-23 {
    margin-left: 95.83333%; }
  .rs-n-23 {
    margin-right: 95.83333%; }
  .col-n-24 {
    width: 100%; }
  .ls-n-24 {
    margin-left: 100%; }
  .rs-n-24 {
    margin-right: 100%; } }

@media (max-width: 1280px) {
  .row {
    width: 100%; }
    .row .row {
      width: auto; }
  .col-m-0 {
    width: 0%; }
  .ls-m-0 {
    margin-left: 0%; }
  .rs-m-0 {
    margin-right: 0%; }
  .col-m-1 {
    width: 4.16667%; }
  .ls-m-1 {
    margin-left: 4.16667%; }
  .rs-m-1 {
    margin-right: 4.16667%; }
  .col-m-2 {
    width: 8.33333%; }
  .ls-m-2 {
    margin-left: 8.33333%; }
  .rs-m-2 {
    margin-right: 8.33333%; }
  .col-m-3 {
    width: 12.5%; }
  .ls-m-3 {
    margin-left: 12.5%; }
  .rs-m-3 {
    margin-right: 12.5%; }
  .col-m-4 {
    width: 16.66667%; }
  .ls-m-4 {
    margin-left: 16.66667%; }
  .rs-m-4 {
    margin-right: 16.66667%; }
  .col-m-5 {
    width: 20.83333%; }
  .ls-m-5 {
    margin-left: 20.83333%; }
  .rs-m-5 {
    margin-right: 20.83333%; }
  .col-m-6 {
    width: 25%; }
  .ls-m-6 {
    margin-left: 25%; }
  .rs-m-6 {
    margin-right: 25%; }
  .col-m-7 {
    width: 29.16667%; }
  .ls-m-7 {
    margin-left: 29.16667%; }
  .rs-m-7 {
    margin-right: 29.16667%; }
  .col-m-8 {
    width: 33.33333%; }
  .ls-m-8 {
    margin-left: 33.33333%; }
  .rs-m-8 {
    margin-right: 33.33333%; }
  .col-m-9 {
    width: 37.5%; }
  .ls-m-9 {
    margin-left: 37.5%; }
  .rs-m-9 {
    margin-right: 37.5%; }
  .col-m-10 {
    width: 41.66667%; }
  .ls-m-10 {
    margin-left: 41.66667%; }
  .rs-m-10 {
    margin-right: 41.66667%; }
  .col-m-11 {
    width: 45.83333%; }
  .ls-m-11 {
    margin-left: 45.83333%; }
  .rs-m-11 {
    margin-right: 45.83333%; }
  .col-m-12 {
    width: 50%; }
  .ls-m-12 {
    margin-left: 50%; }
  .rs-m-12 {
    margin-right: 50%; }
  .col-m-13 {
    width: 54.16667%; }
  .ls-m-13 {
    margin-left: 54.16667%; }
  .rs-m-13 {
    margin-right: 54.16667%; }
  .col-m-14 {
    width: 58.33333%; }
  .ls-m-14 {
    margin-left: 58.33333%; }
  .rs-m-14 {
    margin-right: 58.33333%; }
  .col-m-15 {
    width: 62.5%; }
  .ls-m-15 {
    margin-left: 62.5%; }
  .rs-m-15 {
    margin-right: 62.5%; }
  .col-m-16 {
    width: 66.66667%; }
  .ls-m-16 {
    margin-left: 66.66667%; }
  .rs-m-16 {
    margin-right: 66.66667%; }
  .col-m-17 {
    width: 70.83333%; }
  .ls-m-17 {
    margin-left: 70.83333%; }
  .rs-m-17 {
    margin-right: 70.83333%; }
  .col-m-18 {
    width: 75%; }
  .ls-m-18 {
    margin-left: 75%; }
  .rs-m-18 {
    margin-right: 75%; }
  .col-m-19 {
    width: 79.16667%; }
  .ls-m-19 {
    margin-left: 79.16667%; }
  .rs-m-19 {
    margin-right: 79.16667%; }
  .col-m-20 {
    width: 83.33333%; }
  .ls-m-20 {
    margin-left: 83.33333%; }
  .rs-m-20 {
    margin-right: 83.33333%; }
  .col-m-21 {
    width: 87.5%; }
  .ls-m-21 {
    margin-left: 87.5%; }
  .rs-m-21 {
    margin-right: 87.5%; }
  .col-m-22 {
    width: 91.66667%; }
  .ls-m-22 {
    margin-left: 91.66667%; }
  .rs-m-22 {
    margin-right: 91.66667%; }
  .col-m-23 {
    width: 95.83333%; }
  .ls-m-23 {
    margin-left: 95.83333%; }
  .rs-m-23 {
    margin-right: 95.83333%; }
  .col-m-24 {
    width: 100%; }
  .ls-m-24 {
    margin-left: 100%; }
  .rs-m-24 {
    margin-right: 100%; } }

@media (max-width: 640px) {
  .row {
    width: auto; }
    .row .row {
      width: auto; }
  .col-s-0 {
    width: 0%; }
  .ls-s-0 {
    margin-left: 0%; }
  .rs-s-0 {
    margin-right: 0%; }
  .col-s-1 {
    width: 4.16667%; }
  .ls-s-1 {
    margin-left: 4.16667%; }
  .rs-s-1 {
    margin-right: 4.16667%; }
  .col-s-2 {
    width: 8.33333%; }
  .ls-s-2 {
    margin-left: 8.33333%; }
  .rs-s-2 {
    margin-right: 8.33333%; }
  .col-s-3 {
    width: 12.5%; }
  .ls-s-3 {
    margin-left: 12.5%; }
  .rs-s-3 {
    margin-right: 12.5%; }
  .col-s-4 {
    width: 16.66667%; }
  .ls-s-4 {
    margin-left: 16.66667%; }
  .rs-s-4 {
    margin-right: 16.66667%; }
  .col-s-5 {
    width: 20.83333%; }
  .ls-s-5 {
    margin-left: 20.83333%; }
  .rs-s-5 {
    margin-right: 20.83333%; }
  .col-s-6 {
    width: 25%; }
  .ls-s-6 {
    margin-left: 25%; }
  .rs-s-6 {
    margin-right: 25%; }
  .col-s-7 {
    width: 29.16667%; }
  .ls-s-7 {
    margin-left: 29.16667%; }
  .rs-s-7 {
    margin-right: 29.16667%; }
  .col-s-8 {
    width: 33.33333%; }
  .ls-s-8 {
    margin-left: 33.33333%; }
  .rs-s-8 {
    margin-right: 33.33333%; }
  .col-s-9 {
    width: 37.5%; }
  .ls-s-9 {
    margin-left: 37.5%; }
  .rs-s-9 {
    margin-right: 37.5%; }
  .col-s-10 {
    width: 41.66667%; }
  .ls-s-10 {
    margin-left: 41.66667%; }
  .rs-s-10 {
    margin-right: 41.66667%; }
  .col-s-11 {
    width: 45.83333%; }
  .ls-s-11 {
    margin-left: 45.83333%; }
  .rs-s-11 {
    margin-right: 45.83333%; }
  .col-s-12 {
    width: 50%; }
  .ls-s-12 {
    margin-left: 50%; }
  .rs-s-12 {
    margin-right: 50%; }
  .col-s-13 {
    width: 54.16667%; }
  .ls-s-13 {
    margin-left: 54.16667%; }
  .rs-s-13 {
    margin-right: 54.16667%; }
  .col-s-14 {
    width: 58.33333%; }
  .ls-s-14 {
    margin-left: 58.33333%; }
  .rs-s-14 {
    margin-right: 58.33333%; }
  .col-s-15 {
    width: 62.5%; }
  .ls-s-15 {
    margin-left: 62.5%; }
  .rs-s-15 {
    margin-right: 62.5%; }
  .col-s-16 {
    width: 66.66667%; }
  .ls-s-16 {
    margin-left: 66.66667%; }
  .rs-s-16 {
    margin-right: 66.66667%; }
  .col-s-17 {
    width: 70.83333%; }
  .ls-s-17 {
    margin-left: 70.83333%; }
  .rs-s-17 {
    margin-right: 70.83333%; }
  .col-s-18 {
    width: 75%; }
  .ls-s-18 {
    margin-left: 75%; }
  .rs-s-18 {
    margin-right: 75%; }
  .col-s-19 {
    width: 79.16667%; }
  .ls-s-19 {
    margin-left: 79.16667%; }
  .rs-s-19 {
    margin-right: 79.16667%; }
  .col-s-20 {
    width: 83.33333%; }
  .ls-s-20 {
    margin-left: 83.33333%; }
  .rs-s-20 {
    margin-right: 83.33333%; }
  .col-s-21 {
    width: 87.5%; }
  .ls-s-21 {
    margin-left: 87.5%; }
  .rs-s-21 {
    margin-right: 87.5%; }
  .col-s-22 {
    width: 91.66667%; }
  .ls-s-22 {
    margin-left: 91.66667%; }
  .rs-s-22 {
    margin-right: 91.66667%; }
  .col-s-23 {
    width: 95.83333%; }
  .ls-s-23 {
    margin-left: 95.83333%; }
  .rs-s-23 {
    margin-right: 95.83333%; }
  .col-s-24 {
    width: 100%; }
  .ls-s-24 {
    margin-left: 100%; }
  .rs-s-24 {
    margin-right: 100%; } }

/*	==========================================================================

		design.css
		Main user-interface stylesheet

	========================================================================== */
html, body {
  height: 100%; }

.grid {
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif; }

/*	==========================================================================
		Default Content
	========================================================================== */
h1 {
  font: normal normal 300 48px/46px Quicksand, sans-serif;
  color: #000;
  margin: 24px 0; }

h2 {
  font: normal normal 400 24px/46px Quicksand, sans-serif;
  color: #000;
  margin: 24px 0; }

h3 {
  font: normal normal 400 24px/26px Quicksand, sans-serif;
  color: #000;
  margin: 24px 0; }

h4 {
  font: normal normal 400 16px/26px Quicksand, sans-serif;
  color: #000;
  margin: 24px 0; }

p {
  font: normal normal 300 14px/26px Helvetica, sans-serif;
  color: #000;
  margin: 24px 0; }

ul, ol {
  font: normal normal 300 16px/26px Quicksand, sans-serif;
  color: #000;
  margin: 24px 0; }

a {
  text-decoration: none;
  color: #111; }

strong {
  font-weight: 700; }

.button {
  display: inline-block;
  padding: 20px 50px;
  text-transform: uppercase;
  border: 1px solid #1e824c;
  background: #fff;
  color: #1e824c; }

/*	==========================================================================
		Header
	========================================================================== */
#header #contact-details {
  padding: 15px 0;
  background: #1e824c; }
  #header #contact-details .content {
    text-align: right; }
    #header #contact-details .content ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      #header #contact-details .content ul li {
        display: inline-block;
        margin: 0; }
        #header #contact-details .content ul li a {
          display: inline-block;
          vertical-align: middle;
          line-height: 14px;
          color: #fff; }
          #header #contact-details .content ul li a i {
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
            font-size: 20px;
            color: #fff; }
          #header #contact-details .content ul li a span {
            display: inline-block;
            vertical-align: middle; }
        #header #contact-details .content ul li:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin: 0 10px;
          width: 1px;
          height: 15px;
          background: #fff; }
        #header #contact-details .content ul li:last-child:after {
          content: none; }
        #header #contact-details .content ul li:last-child a {
          border: 0; }
    #header #contact-details .content #mobile-navigation {
      display: none; }

#header #navigation {
  padding: 30px 0 10px 0; }
  #header #navigation [class*='col-'] {
    vertical-align: middle; }
  #header #navigation .logo img {
    width: 100%; }
  #header #navigation .primary-navigation #desktop-navigation {
    text-align: right; }
    #header #navigation .primary-navigation #desktop-navigation ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 18px; }
      #header #navigation .primary-navigation #desktop-navigation ul li {
        display: inline-block;
        margin: 0 0 0 15px;
        text-transform: uppercase; }
        #header #navigation .primary-navigation #desktop-navigation ul li:first-child {
          margin: 0; }
        #header #navigation .primary-navigation #desktop-navigation ul li a:active {
          color: #8e2222; }
      #header #navigation .primary-navigation #desktop-navigation ul .active a {
        color: #7d0000; }

@media (max-width: 1279px) {
  #header #contact-details .content {
    padding-right: 30px;
    text-align: right; }
  #header #navigation .primary-navigation {
    padding-right: 30px; } }

@media (max-width: 639px) {
  #header #contact-details {
    position: fixed;
    z-index: 300;
    width: 100%; }
    #header #contact-details .content {
      text-align: left;
      padding-right: 10px; }
      #header #contact-details .content ul {
        display: inline-block;
        vertical-align: middle; }
        #header #contact-details .content ul li a span {
          display: none; }
        #header #contact-details .content ul li a i {
          margin: 0 15px;
          font-size: 25px; }
      #header #contact-details .content #mobile-navigation {
        display: inline-block !important;
        float: right;
        text-align: center;
        width: 75px;
        padding: 5px 5px 0 0;
        vertical-align: middle; }
        #header #contact-details .content #mobile-navigation ul {
          position: fixed;
          z-index: 100;
          top: -100%;
          right: 0;
          padding: 0px 0 40px;
          width: 50%;
          height: 100%;
          transition: top 250ms ease-in-out;
          background: #1e824c; }
          #header #contact-details .content #mobile-navigation ul.show {
            top: 57px; }
          #header #contact-details .content #mobile-navigation ul .active {
            background: rgba(77, 175, 124, 0.5); }
          #header #contact-details .content #mobile-navigation ul li {
            display: block;
            text-align: left;
            font-size: 20px;
            border-bottom: 1px solid rgba(238, 238, 238, 0.2); }
            #header #contact-details .content #mobile-navigation ul li:after {
              content: none; }
            #header #contact-details .content #mobile-navigation ul li:last-child {
              border: none; }
            #header #contact-details .content #mobile-navigation ul li:hover {
              background: rgba(77, 175, 124, 0.5); }
            #header #contact-details .content #mobile-navigation ul li a {
              padding: 10px 20px;
              width: 100%;
              height: 100%; }
        #header #contact-details .content #mobile-navigation .menu {
          display: inline-block;
          width: 26px;
          height: 100%;
          cursor: pointer;
          z-index: 200;
          position: fixed; }
          #header #contact-details .content #mobile-navigation .menu:before, #header #contact-details .content #mobile-navigation .menu:after, #header #contact-details .content #mobile-navigation .menu span {
            height: 2px;
            width: 100%;
            background: #fff;
            display: block;
            border-radius: 2px;
            transition: all 300ms ease-in-out; }
          #header #contact-details .content #mobile-navigation .menu:before, #header #contact-details .content #mobile-navigation .menu:after {
            content: ''; }
          #header #contact-details .content #mobile-navigation .menu:before {
            top: 0; }
          #header #contact-details .content #mobile-navigation .menu:after {
            bottom: 0; }
          #header #contact-details .content #mobile-navigation .menu span {
            top: 50%;
            margin: 6px 0; }
          #header #contact-details .content #mobile-navigation .menu p {
            opacity: 1;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            font-size: 10px;
            color: #fff;
            margin: 0;
            text-transform: uppercase;
            transition: opacity 250ms ease-in-out; }
          #header #contact-details .content #mobile-navigation .menu.close p {
            opacity: 0; }
          #header #contact-details .content #mobile-navigation .menu.close span {
            width: 0%; }
          #header #contact-details .content #mobile-navigation .menu.close:before {
            transform: rotate(40deg);
            transform-origin: top left; }
          #header #contact-details .content #mobile-navigation .menu.close:after {
            transform: rotate(-40deg);
            transform-origin: bottom left; }
  #header #navigation {
    padding: 70px 0 10px 0; }
    #header #navigation #desktop-navigation {
      display: none; } }

/*	==========================================================================
		Main
	========================================================================== */
#main {
  position: relative; }
  #main #header-image {
    padding: 80px 0;
    height: 500px;
    background: transparent center center no-repeat;
    background-size: cover; }
    @media (max-width: 639px) {
      #main #header-image {
        background-position-x: -540px; } }
    #main #header-image .row {
      height: 100%; }
      #main #header-image .row:before {
        content: '';
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle; }
    #main #header-image .row > div {
      vertical-align: middle;
      margin-top: 90px; }
      @media (max-width: 1279px) {
        #main #header-image .row > div {
          margin-top: 0; } }
      @media (max-width: 639px) {
        #main #header-image .row > div {
          margin-top: 0; } }
    #main #header-image .content {
      text-align: center; }
      #main #header-image .content h1 {
        color: #fff; }
      #main #header-image .content p {
        margin-top: 90px;
        font-size: 18px; }
        @media (max-width: 1279px) {
          #main #header-image .content p {
            margin-top: 50px; } }
        @media (max-width: 639px) {
          #main #header-image .content p {
            margin-top: 50px; } }
        #main #header-image .content p .button {
          border: 0;
          color: #1e824c; }
          #main #header-image .content p .button:hover {
            background: #1e824c;
            color: #fff;
            transition: 250ms ease-in-out; }
  #main #header-image-big {
    padding: 80px 0;
    height: 600px;
    background: transparent center center no-repeat;
    background-size: cover; }
    @media (max-width: 1279px) {
      #main #header-image-big {
        height: 400px; } }
    @media (max-width: 639px) {
      #main #header-image-big {
        height: 400px; } }
    #main #header-image-big .row {
      height: 100%; }
      #main #header-image-big .row:before {
        content: '';
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle; }
    #main #header-image-big .row > div {
      vertical-align: middle;
      margin-top: -150px; }
      @media (max-width: 1279px) {
        #main #header-image-big .row > div {
          margin-top: 0; } }
      @media (max-width: 639px) {
        #main #header-image-big .row > div {
          margin-top: 0; } }
    #main #header-image-big .content {
      text-align: center; }
      #main #header-image-big .content h1 {
        color: #fff; }
      #main #header-image-big .content p {
        margin-top: 90px;
        font-size: 18px; }
        #main #header-image-big .content p .button {
          border: 0;
          color: #1e824c; }
          #main #header-image-big .content p .button:hover {
            background: #1e824c;
            color: #fff;
            transition: 250ms ease-in-out; }
  #main #header-image-medium {
    padding: 80px 0;
    height: 300px;
    background: transparent center center no-repeat;
    background-size: cover; }
    @media (max-width: 1279px) {
      #main #header-image-medium {
        height: 200px; } }
    @media (max-width: 639px) {
      #main #header-image-medium {
        height: 200px; } }
    #main #header-image-medium .row {
      height: 100%; }
      #main #header-image-medium .row:before {
        content: '';
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle; }
    #main #header-image-medium .row > div {
      vertical-align: middle; }
      @media (max-width: 1279px) {
        #main #header-image-medium .row > div {
          margin-top: 0; } }
      @media (max-width: 639px) {
        #main #header-image-medium .row > div {
          margin-top: 0; } }
    #main #header-image-medium .content {
      text-align: center; }
      #main #header-image-medium .content h1 {
        color: #fff; }
      #main #header-image-medium .content p {
        margin-top: 90px;
        font-size: 18px; }
        #main #header-image-medium .content p .button {
          border: 0;
          color: #1e824c; }
          #main #header-image-medium .content p .button:hover {
            background: #1e824c;
            color: #fff;
            transition: 250ms ease-in-out; }
  #main #services-container {
    padding: 20px 0; }
    #main #services-container .container {
      z-index: 10; }
    #main #services-container .row {
      z-index: 10;
      margin-top: -60px;
      padding: 30px 0 20px 0;
      background: #fff; }
    #main #services-container header {
      text-align: center; }
      #main #services-container header h2 {
        text-transform: uppercase; }
    #main #services-container #services .content {
      padding: 25px 0;
      text-align: center; }
      #main #services-container #services .content h3 {
        text-transform: uppercase;
        color: #7d0000; }
      #main #services-container #services .content i {
        font-size: 65px;
        color: #1e824c; }
      #main #services-container #services .content p {
        font-size: 20px; }
        #main #services-container #services .content p .button {
          font-size: 14px;
          color: #000; }
          #main #services-container #services .content p .button:hover {
            background: #1e824c;
            color: #fff;
            transition: 250ms ease-in-out; }
  #main #about {
    position: relative;
    margin-top: -190px;
    margin-bottom: 79px;
    padding: 250px 0 80px 0;
    background: #1e824c; }
    #main #about .content h2 {
      margin: 24px 0 40px 0;
      text-transform: uppercase;
      color: #fff; }
    #main #about .content p {
      font-size: 16px;
      color: #fff; }
    #main #about .layover {
      position: absolute;
      bottom: 0;
      margin-bottom: -50px;
      width: 100%; }
      #main #about .layover .arrow {
        position: absolute;
        width: 0;
        height: 0;
        top: -50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-left: 40px solid transparent;
        border-right: 40px solid transparent;
        border-top: 40px solid #1e824c; }
  #main #selling-points {
    padding: 90px 0 200px 0;
    background: #f8f8f8; }
    @media (max-width: 1679px) {
      #main #selling-points #client-reviews {
        padding: 0 80px; } }
    @media (max-width: 1279px) {
      #main #selling-points #client-reviews {
        padding: 0 30px; } }
    @media (max-width: 639px) {
      #main #selling-points #client-reviews {
        padding: 0 30px; } }
    @media (max-width: 1279px) {
      #main #selling-points #client-reviews header {
        text-align: center; } }
    #main #selling-points #client-reviews header h2 {
      text-transform: uppercase; }
    #main #selling-points #client-reviews .reviews {
      padding: 20px 0; }
      #main #selling-points #client-reviews .reviews .review-item {
        padding: 20px 0; }
        #main #selling-points #client-reviews .reviews .review-item [class*='col-'] {
          vertical-align: middle; }
        #main #selling-points #client-reviews .reviews .review-item:nth-child(even) {
          direction: rtl; }
          #main #selling-points #client-reviews .reviews .review-item:nth-child(even) .image-container {
            padding-left: 25px; }
            @media (max-width: 1279px) {
              #main #selling-points #client-reviews .reviews .review-item:nth-child(even) .image-container {
                padding-left: 0; } }
          #main #selling-points #client-reviews .reviews .review-item:nth-child(even) .content {
            padding: 10px 50px 10px 30px; }
            @media (max-width: 1279px) {
              #main #selling-points #client-reviews .reviews .review-item:nth-child(even) .content {
                padding: 10px 40px; } }
          #main #selling-points #client-reviews .reviews .review-item:nth-child(even) .arrow {
            margin: 0;
            font-size: 0;
            width: 0;
            height: 0;
            border-top: 60px solid transparent;
            border-bottom: 60px solid transparent;
            border-left: 54px solid #fff; }
        #main #selling-points #client-reviews .reviews .review-item:nth-child(odd) .image-container {
          padding-right: 25px; }
          @media (max-width: 1279px) {
            #main #selling-points #client-reviews .reviews .review-item:nth-child(odd) .image-container {
              padding-right: 0; } }
        #main #selling-points #client-reviews .reviews .review-item:nth-child(odd) .content {
          padding: 10px 30px 10px 50px; }
          @media (max-width: 1279px) {
            #main #selling-points #client-reviews .reviews .review-item:nth-child(odd) .content {
              padding: 10px 40px; } }
        #main #selling-points #client-reviews .reviews .review-item:nth-child(odd) .arrow {
          margin: 0;
          font-size: 0;
          width: 0;
          height: 0;
          border-top: 60px solid transparent;
          border-bottom: 60px solid transparent;
          border-right: 54px solid #fff; }
        @media (max-width: 1279px) {
          #main #selling-points #client-reviews .reviews .review-item .arrow {
            display: none; } }
        @media (max-width: 1279px) {
          #main #selling-points #client-reviews .reviews .review-item .imagery {
            text-align: center;
            margin-bottom: 20px; } }
        #main #selling-points #client-reviews .reviews .review-item .imagery img {
          width: 100%; }
          @media (max-width: 1279px) {
            #main #selling-points #client-reviews .reviews .review-item .imagery img {
              width: 50%; } }
        #main #selling-points #client-reviews .reviews .review-item .content {
          min-height: 120px;
          background: #fff; }
          @media (max-width: 1279px) {
            #main #selling-points #client-reviews .reviews .review-item .content {
              min-height: 0; } }
          @media (max-width: 639px) {
            #main #selling-points #client-reviews .reviews .review-item .content {
              min-height: 0; } }
          #main #selling-points #client-reviews .reviews .review-item .content p {
            font-size: 16px; }
    @media (max-width: 1279px) {
      #main #selling-points #usp-list header {
        text-align: center; } }
    #main #selling-points #usp-list .usps {
      padding: 40px 0; }
      #main #selling-points #usp-list .usps .content ul {
        margin: 0; }
        #main #selling-points #usp-list .usps .content ul li {
          padding-left: 25px; }
    #main #selling-points #usp-list .imagery img {
      width: 100%;
      height: 100%; }
  #main #about-consultant {
    margin-top: -80px; }
    #main #about-consultant #consultant .container {
      padding: 90px 0;
      background: #4daf7c; }
      @media (max-width: 1279px) {
        #main #about-consultant #consultant .container {
          display: table;
          padding: 80px 180px; } }
      @media (max-width: 639px) {
        #main #about-consultant #consultant .container {
          padding: 80px 80px; } }
      @media (max-width: 1279px) {
        #main #about-consultant #consultant .container .content-container {
          display: table-footer-group; } }
      @media (max-width: 1279px) {
        #main #about-consultant #consultant .container .content-container .content {
          margin-top: 50px;
          text-align: center; } }
      #main #about-consultant #consultant .container .content-container .content h2 {
        margin: 0;
        line-height: 30px;
        font-size: 28px;
        color: #fff; }
      #main #about-consultant #consultant .container .content-container .content h3 {
        margin-top: 0;
        font-size: 20px;
        color: #fff; }
      #main #about-consultant #consultant .container .content-container .content p {
        font-size: 16px;
        color: #fff; }
        #main #about-consultant #consultant .container .content-container .content p .button {
          float: right;
          margin-top: 70px;
          padding: 20px 80px;
          border: 0;
          color: #4daf7c; }
          @media (max-width: 1279px) {
            #main #about-consultant #consultant .container .content-container .content p .button {
              float: none !important;
              margin-top: 30px !important;
              padding: 20px 50px !important;
              width: auto;
              text-align: center; } }
          #main #about-consultant #consultant .container .content-container .content p .button:hover {
            transition: 250ms ease-in-out;
            background: #1e824c;
            color: #fff; }
      @media (max-width: 1279px) {
        #main #about-consultant #consultant .container .image-container {
          display: table-header-group; } }
      #main #about-consultant #consultant .container .image-container .imagery .circle {
        margin: 0 auto;
        height: 300px;
        width: 300px;
        background: transparent center center no-repeat;
        background-size: cover;
        border-radius: 100%; }
        @media (max-width: 1279px) {
          #main #about-consultant #consultant .container .image-container .imagery .circle {
            width: 200px;
            height: 200px; } }
  #main #services-cta {
    padding: 75px 0; }
    #main #services-cta #cta .content h2 {
      text-transform: uppercase; }
    #main #services-cta #cta .content p {
      font-size: 16px; }
      #main #services-cta #cta .content p .button {
        float: right;
        margin-top: 50px;
        background: #1e824c;
        color: #fff; }
        @media (max-width: 639px) {
          #main #services-cta #cta .content p .button {
            width: 100%;
            padding: 20px 0;
            text-align: center; } }
        #main #services-cta #cta .content p .button:hover {
          background: #fff;
          color: #1e824c;
          border: 1px solid #1e824c; }
  #main .image-banner {
    height: 500px;
    background: transparent center center no-repeat;
    background-size: cover; }
  #main #contact-form {
    padding: 60px 0 30px 0; }
    @media (max-width: 639px) {
      #main #contact-form #contact-information {
        display: table; } }
    @media (max-width: 639px) {
      #main #contact-form #contact-information .content-container {
        display: table-footer-group; } }
    #main #contact-form #contact-information .content-container .content h2 {
      margin-top: 0; }
    @media (max-width: 639px) {
      #main #contact-form #contact-information .imagery-container {
        display: table-header-group; } }
    #main #contact-form #contact-information .imagery-container .imagery img {
      width: 100%; }
  #main #our-services #services .services-item {
    padding: 45px 0;
    border-bottom: 1px solid #ddd; }
    #main #our-services #services .services-item [class*='col-'] {
      vertical-align: middle; }
    #main #our-services #services .services-item .content h2 {
      margin-bottom: 5px;
      font-size: 22px;
      color: #7d0000; }
    #main #our-services #services .services-item .content p {
      margin-top: 5px;
      font-size: 16px; }
    #main #our-services #services .services-item .imagery img {
      width: 100%; }
  #main #our-services #services .contact-cta {
    text-align: right; }
    #main #our-services #services .contact-cta p {
      margin: 45px 0; }
      #main #our-services #services .contact-cta p .button {
        text-align: center;
        background: #1e824c;
        color: #fff; }
        #main #our-services #services .contact-cta p .button:hover {
          transition: 250ms ease-in-out;
          border: 1px solid #1e824c;
          background: #fff;
          color: #1e824c; }
  #main #usp {
    padding: 60px 0 100px 0;
    background: #f8f8f8; }
    #main #usp #usps .usp-item {
      padding: 0 60px 0 60px; }
      #main #usp #usps .usp-item .content .number {
        position: relative;
        font-size: 24px; }
        #main #usp #usps .usp-item .content .number:after {
          content: '';
          position: absolute;
          display: inline-block;
          margin-left: 10px;
          top: 80%;
          width: 90%;
          height: 1px;
          background: #000; }
      #main #usp #usps .usp-item .content p {
        font-size: 24px; }
  #main #contact-cta {
    height: 350px;
    font-size: 0; }
    @media (max-width: 639px) {
      #main #contact-cta {
        height: auto; } }
    #main #contact-cta .container a .overlay1, #main #contact-cta .container a .overlay2 {
      position: relative;
      display: inline-block;
      width: 50%;
      height: 350px;
      transition: opacity 0.5s ease;
      -webkit-transition: opacity 0.5s ease;
      -moz-transition: opacity 0.5s ease; }
      @media (max-width: 639px) {
        #main #contact-cta .container a .overlay1, #main #contact-cta .container a .overlay2 {
          display: block;
          width: 100%; } }
      #main #contact-cta .container a .overlay1:after, #main #contact-cta .container a .overlay2:after {
        content: '\A';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        opacity: 1;
        transition: opacity 0.5s;
        -webkit-transition: opacity 0.5s;
        -moz-transition: opacity 0.5s; }
      #main #contact-cta .container a .overlay1:hover:after, #main #contact-cta .container a .overlay2:hover:after {
        opacity: 0.2; }
      #main #contact-cta .container a .overlay1:hover .content, #main #contact-cta .container a .overlay2:hover .content {
        background: rgba(30, 130, 76, 0.5); }
    #main #contact-cta .container a .imagery {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: transparent center center no-repeat;
      background-size: cover; }
      #main #contact-cta .container a .imagery .content {
        position: relative;
        display: inline-block;
        z-index: 100;
        padding: 0px 40px;
        top: 50%;
        margin-top: -50px; }
        #main #contact-cta .container a .imagery .content p {
          font-size: 24px;
          color: #fff; }
  #main #consultant #consultant-information .container {
    position: relative;
    z-index: 100;
    margin-top: -150px;
    margin-bottom: 40px;
    padding: 50px 0 80px 0;
    background: #1e824c; }
    @media (max-width: 1279px) {
      #main #consultant #consultant-information .container {
        margin: 0; } }
    @media (max-width: 639px) {
      #main #consultant #consultant-information .container {
        margin: 0; } }
    @media (max-width: 639px) {
      #main #consultant #consultant-information .container header {
        padding-left: 8.33333%;
        padding-right: 8.33333%; } }
    #main #consultant #consultant-information .container header h2 {
      line-height: 30px;
      margin: 0;
      font-size: 28px;
      color: #fff; }
    #main #consultant #consultant-information .container header p {
      margin: 0;
      font-size: 20px;
      color: #fff; }
    @media (max-width: 639px) {
      #main #consultant #consultant-information .container {
        display: table; } }
    @media (max-width: 639px) {
      #main #consultant #consultant-information .container .content-container {
        display: table-footer-group; } }
    @media (max-width: 639px) {
      #main #consultant #consultant-information .container .content-container .content {
        padding-left: 8.33333%;
        padding-right: 8.33333%; } }
    #main #consultant #consultant-information .container .content-container .content h3 {
      font-size: 21px;
      color: #fff; }
    #main #consultant #consultant-information .container .content-container .content p {
      font-size: 16px;
      color: #fff; }
    @media (max-width: 639px) {
      #main #consultant #consultant-information .container .image-container {
        display: table-header-group; } }
    #main #consultant #consultant-information .container .image-container .imagery {
      margin-top: 100px; }
      @media (max-width: 639px) {
        #main #consultant #consultant-information .container .image-container .imagery {
          margin-top: 20px; } }
      #main #consultant #consultant-information .container .image-container .imagery .circle {
        margin: 0 auto;
        height: 300px;
        width: 300px;
        background: transparent center center no-repeat;
        background-size: cover;
        border-radius: 100%; }
        @media (max-width: 1279px) {
          #main #consultant #consultant-information .container .image-container .imagery .circle {
            height: 200px;
            width: 200px; } }
        @media (max-width: 639px) {
          #main #consultant #consultant-information .container .image-container .imagery .circle {
            margin-bottom: 70px; } }
  #main #base-content {
    padding: 40px 0; }

/*	==========================================================================
		Footer
	========================================================================== */
#footer {
  padding: 60px 0 125px 0;
  background: #1e824c; }
  #footer [class*='col-'] {
    vertical-align: middle; }
  @media (max-width: 1279px) {
    #footer .contact-details {
      padding: 10px 0; } }
  #footer .contact-details ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    @media (max-width: 1279px) {
      #footer .contact-details ul {
        text-align: center; } }
    #footer .contact-details ul li {
      display: inline-block;
      margin: 0; }
      #footer .contact-details ul li a {
        display: inline-block;
        vertical-align: middle;
        line-height: 14px;
        color: #fff; }
        #footer .contact-details ul li a i {
          display: inline-block;
          vertical-align: middle;
          margin-right: 12px;
          font-size: 20px;
          color: #fff; }
        #footer .contact-details ul li a span {
          display: inline-block;
          vertical-align: middle; }
      #footer .contact-details ul li:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
        width: 1px;
        height: 15px;
        background: #fff; }
      #footer .contact-details ul li:last-child:after {
        content: none; }
      #footer .contact-details ul li:last-child a {
        border: 0; }
  #footer nav {
    text-align: center; }
    #footer nav ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      #footer nav ul .first {
        margin: 0; }
      #footer nav ul li {
        display: inline-block;
        margin: 0 0 0 15px; }
        #footer nav ul li a {
          color: #fff; }

/*	==========================================================================
		Form
	========================================================================== */
form {
  padding: 40px 0 60px; }
  form fieldset {
    border: 0;
    margin: 0;
    /**
				Fields
			*/
    /**
				Default
			*/ }
    form fieldset form .radio-field p,
    form fieldset label {
      margin: 0;
      display: block;
      font-size: 18px;
      font-weight: 400;
      font-family: Quicksand, sans-serif;
      text-transform: uppercase;
      color: #666; }
    form fieldset input {
      font-family: Quicksand, sans-serif; }
      form fieldset input[type=text], form fieldset input[type=date], form fieldset input[type=email] {
        display: block;
        width: 100%;
        line-height: 42px;
        height: 42px;
        padding: 0 10px;
        color: #666;
        border: 1px solid #ddd;
        border-radius: 2px;
        transition: border 100ms ease-in-out; }
    form fieldset textarea {
      font-family: Quicksand, sans-serif;
      display: block;
      width: 100%;
      padding: 5px 10px;
      height: 250px;
      line-height: 24px;
      color: #666;
      border: 1px solid #ddd;
      border-radius: 2px;
      transition: border 100ms ease-in-out; }
      form fieldset textarea:focus {
        border-color: #1e824c;
        outline: none; }
    form fieldset .submit-field,
    form fieldset .input-field,
    form fieldset .radio-field {
      padding: 15px 5px;
      position: relative; }
    form fieldset .input-field label {
      margin: 0 0 30px; }
    form fieldset .input-field input:focus {
      border-color: #1e824c;
      outline: none; }
    form fieldset .radio-field p {
      margin-bottom: 10px; }
    form fieldset .radio-field label {
      display: inline-block;
      margin: 0 20px 0 0;
      vertical-align: middle; }
    form fieldset .radio-field input {
      display: inline-block;
      margin: 0 0 0 0;
      opacity: 0; }
    form fieldset .radio-field label:before {
      content: '';
      display: inline-block;
      background: #999;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      vertical-align: middle;
      transition: background 250ms ease-in-out; }
    form fieldset .radio-field label.checked:before, form fieldset .radio-field label.selected:before {
      background: #ff4200;
      animation: blip 0.5s; }
    form fieldset .checkbox-field {
      padding: 10px 0; }
      form fieldset .checkbox-field label {
        display: inline-block;
        vertical-align: middle; }
      form fieldset .checkbox-field input {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 2px 5px; }
    form fieldset .info-field {
      color: #999;
      vertical-align: bottom;
      margin-bottom: 22px;
      text-align: center;
      position: relative;
      z-index: 2; }
      form fieldset .info-field i {
        cursor: pointer; }
      form fieldset .info-field .info-box {
        display: none;
        background: #333;
        border-radius: 2px;
        position: absolute;
        right: 50%;
        bottom: 30px;
        margin-left: 20px;
        width: 150px;
        padding: 10px 10px;
        margin-right: -14px; }
        form fieldset .info-field .info-box:before {
          content: '▼';
          font-family: 'Times';
          position: absolute;
          right: 10px;
          bottom: -10px;
          margin-top: -6px;
          color: #333; }
        form fieldset .info-field .info-box p {
          color: #fff;
          margin: 0;
          padding: 0;
          line-height: 18px;
          font-weight: 600;
          font-size: 12px;
          font-family: Arial, sans-serif; }
      form fieldset .info-field:hover .info-box {
        display: block; }
    form fieldset .disclaimer {
      margin-left: 20px;
      color: #666; }
      form fieldset .disclaimer:before {
        content: '*';
        font-weight: 600;
        margin-left: -20px;
        display: inline-block;
        width: 20px;
        text-align: center; }
    form fieldset [type=submit] {
      padding: 20px 100px;
      border: 1px solid #1e824c;
      background: #1e824c;
      color: #fff;
      font-weight: 300;
      font-family: Quicksand, sans-serif;
      transition: all 150ms ease-in-out; }
      form fieldset [type=submit]:hover {
        background: #fff;
        color: #1e824c; }
    form fieldset label em {
      padding: 0 4px;
      font-style: normal;
      color: #000; }
    form fieldset input[name=licenseplate] {
      width: 100px; }
    form fieldset .error-field {
      position: relative; }
      form fieldset .error-field span {
        display: block;
        background: #222;
        line-height: 18px;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #fff;
        border-radius: 2px;
        position: absolute;
        right: 30px;
        bottom: 0;
        margin-left: 20px;
        min-width: 120px;
        padding: 10px 10px; }
        form fieldset .error-field span:after {
          content: '◣';
          position: absolute;
          left: 100%;
          bottom: 5px;
          margin-left: -2px;
          font-family: 'Times',sans-serif;
          color: #222; }
  form .LV_validation_message {
    position: absolute;
    bottom: 57px;
    right: 5px;
    font-weight: 400;
    font-family: Arial, sans-serif;
    color: #666; }
  form .LV_validation_message.LV_invalid i {
    color: #ff4200; }

@keyframes blip {
  0% {
    background: #666; }
  50% {
    transform: scale(1.05); }
  100% {
    background: #ff4200;
    transform: scale(1); } }
