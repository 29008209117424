
// Predefined Break-points
$mediaMaxWidth: 1679px;
$mediaBp1Width: 1279px;
$mediaMinWidth: 639px;

@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "retina": "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
    "desktop": "(min-width: #{$mediaMaxWidth + 1})",
    "<desktop": "(max-width: #{$mediaMaxWidth})",
    "laptop": "(min-width: #{$mediaBp1Width + 1})",
    "<laptop": "(max-width: #{$mediaBp1Width})",
    "tablet": "(min-width: #{$mediaMinWidth + 1})",
    "<tablet": "(max-width: #{$mediaMinWidth})"
  );
  @return map-get( $condMap, $c );
}

@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ( $query != "" ) {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query}  { @content; }
}
