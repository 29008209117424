/*	==========================================================================

		design.css
		Main user-interface stylesheet

	========================================================================== */

	html, body { height: 100%; }
	.grid { width: 100%; margin: 0 auto; font-family: Arial, sans-serif; }

/*	==========================================================================
		Default Content
	========================================================================== */

	h1 { font: normal normal 300 48px/46px Quicksand, sans-serif; color: #000; margin: 24px 0; }
	h2 { font: normal normal 400 24px/46px Quicksand, sans-serif; color: #000; margin: 24px 0; }
	h3 { font: normal normal 400 24px/26px Quicksand, sans-serif; color: #000; margin: 24px 0; }
	h4 { font: normal normal 400 16px/26px Quicksand, sans-serif; color: #000; margin: 24px 0; }
	p  { font: normal normal 300 14px/26px Quicksand, sans-serif; color: #000; margin: 24px 0; }
	ul, ol { font: normal normal 300 16px/26px Quicksand, sans-serif; color: #000; margin: 24px 0; }
	a { text-decoration: none; color: #111; }
	strong { font-weight: 700; }
	.button { display: inline-block; padding: 20px 50px; text-transform: uppercase; border: 1px solid #1e824c; background: #fff; color: #1e824c; }

/*	==========================================================================
		Header
	========================================================================== */

	#header {
		#contact-details { padding: 15px 0; background: #1e824c;
			.content { text-align: right;
				ul { margin: 0; padding: 0; list-style: none;
					li{ display: inline-block; margin: 0;
						a { display: inline-block; vertical-align: middle; line-height: 14px; color: #fff;
							i { display: inline-block; vertical-align: middle; margin-right: 12px; font-size: 20px; color: #fff; }
							span { display: inline-block; vertical-align: middle; }
						}
						&:after { content: ''; display: inline-block; vertical-align: middle; margin: 0 10px; width: 1px; height: 15px; background: #fff; }
						&:last-child {
							&:after { content: none; }
							a { border: 0; }
						}
					}
				}
				#mobile-navigation { display: none; }
			}
		}
		#navigation { padding: 30px 0 10px 0;
			[class*='col-'] { vertical-align: middle; }
			.logo {
				img { width: 100%; }
			}
			.primary-navigation {
				#desktop-navigation { text-align: right;
					ul { margin: 0; padding: 0; list-style: none; font-size: 18px;
						li { display: inline-block; margin: 0 0 0 15px; text-transform: uppercase;
							&:first-child { margin: 0; }
							a {
								&:active { color: #8e2222; }
							}
						}
						.active {
							a { color: #7d0000; }
						}
					}
				}
			}
		}

		@include media('<laptop') {
			#contact-details {
				.content { padding-right: 30px; text-align: right;

				}
			}
			#navigation {
				.primary-navigation { padding-right: 30px; }
			}
		}
		@include media('<tablet') {
			#contact-details { position: fixed; z-index: 300; width: 100%;
				.content { text-align: left; padding-right: 10px;
					ul { display: inline-block; vertical-align: middle;
						li {
							a {
								span { display: none; }
								i { margin: 0 15px; font-size: 25px; }
							}
						}
					}
					#mobile-navigation { display: inline-block !important; float: right; text-align: center; width: 75px; padding: 5px 5px 0 0; vertical-align: middle;
						ul { position: fixed; z-index: 100; top: -100%; right: 0; padding: 0px 0 40px; width: 50%; height: 100%; transition: top 250ms ease-in-out; background: #1e824c;
							&.show { top: 57px; }
							.active { background: 	rgba(77, 175, 124,0.5); }
							li { display: block; text-align: left; font-size: 20px; border-bottom: 1px solid rgba(238, 238, 238,0.2);
								&:after { content: none; }
								&:last-child { border: none; }
								&:hover { background: rgba(77, 175, 124,0.5); }
								a { padding: 10px 20px; width: 100%; height: 100%; }
							}
						}
						.menu { display: inline-block; width: 26px; height: 100%; cursor: pointer; z-index: 200; position: fixed;
							&:before, &:after, span { height: 2px; width: 100%; background: #fff; display: block; border-radius: 2px; transition: all 300ms ease-in-out; }
							&:before, &:after { content: ''; }
							&:before { top: 0; }
							&:after { bottom: 0; }
							span { top: 50%; margin: 6px 0; }
							p { opacity: 1; position: absolute; top: 100%; left: 0; width: 100%; font-size: 10px; color: #fff; margin: 0; text-transform: uppercase; transition: opacity 250ms ease-in-out; }

							&.close {
								p { opacity: 0; }
								span { width: 0%; }
								&:before { transform: rotate(40deg); transform-origin: top left; }
								&:after { transform: rotate(-40deg); transform-origin: bottom left; }
							}
						}
					}
				}
			}
			#navigation { padding: 70px 0 10px 0;
				#desktop-navigation { display: none; }
			}
		}
	}


/*	==========================================================================
		Main
	========================================================================== */

	#main { position: relative;
		#header-image { padding: 80px 0; height: 500px; background: transparent center center no-repeat; background-size: cover;
			@include media('<tablet') {
				background-position-x: -540px;
			}
			.row { height: 100%;
				&:before { content: ''; display: inline-block; height: 100%; width: 0; vertical-align: middle; }
			}
			.row>div { vertical-align: middle; margin-top: 90px;
				@include media('<laptop') { margin-top: 0; }
				@include media('<tablet') { margin-top: 0; }
			}
			.content { text-align: center;
				h1 { color: #fff; }
				p { margin-top: 90px; font-size: 18px;
					@include media('<laptop') { margin-top: 50px; }
					@include media('<tablet') { margin-top: 50px; }
					.button { border: 0; color: #1e824c;
						&:hover { background: #1e824c; color: #fff; transition: 250ms ease-in-out; }
					}
				}
			}
		}
		#header-image-big { padding: 80px 0; height: 600px; background: transparent center center no-repeat; background-size: cover;
			@include media('<laptop') { height: 400px; }
			@include media('<tablet') { height: 400px; }
			.row { height: 100%;
				&:before { content: ''; display: inline-block; height: 100%; width: 0; vertical-align: middle; }
			}
			.row>div { vertical-align: middle; margin-top: -150px;
				@include media('<laptop') { margin-top: 0; }
				@include media('<tablet') { margin-top: 0; }
			}
			.content { text-align: center;
				h1 { color: #fff; }
				p { margin-top: 90px; font-size: 18px;
					.button { border: 0; color: #1e824c;
						&:hover { background: #1e824c; color: #fff; transition: 250ms ease-in-out; }
					}
				}
			}
		}

		#header-image-medium { padding: 80px 0; height: 300px; background: transparent center center no-repeat; background-size: cover;
			@include media('<laptop') { height: 200px; }
			@include media('<tablet') { height: 200px; }
			.row { height: 100%;
				&:before { content: ''; display: inline-block; height: 100%; width: 0; vertical-align: middle; }
			}
			.row>div { vertical-align: middle;
				@include media('<laptop') { margin-top: 0; }
				@include media('<tablet') { margin-top: 0; }
			}
			.content { text-align: center;
				h1 { color: #fff; }
				p { margin-top: 90px; font-size: 18px;
					.button { border: 0; color: #1e824c;
						&:hover { background: #1e824c; color: #fff; transition: 250ms ease-in-out; }
					}
				}
			}
		}

		#services-container { padding: 20px 0;
			.container { z-index: 10; }
			.row { z-index: 10; margin-top: -60px; padding: 30px 0 20px 0; background: #fff; }
			header { text-align: center;
				h2 { text-transform: uppercase; }
			}
			#services {
				.content { padding: 25px 0; text-align: center;
					h3 { text-transform: uppercase; color: #7d0000; }
					i { font-size: 65px; color: #1e824c; }
					p { font-size: 20px;
						.button { font-size: 14px; color: #000;
							&:hover { background: #1e824c; color: #fff; transition: 250ms ease-in-out; }
						}
					}
				}
			}
		}
		#about { position: relative; margin-top: -190px; margin-bottom: 79px; padding: 250px 0 80px 0; background: #1e824c;
			.content {
				h2 { margin: 24px 0 40px 0; text-transform: uppercase; color: #fff; }
				p { font-size: 16px; color: #fff; }
			}
			.layover { position: absolute; bottom: 0; margin-bottom: -50px; width: 100%;
				.arrow { position: absolute; width: 0; height: 0; top: -50px; left: 0; right: 0; margin: 0 auto; border-left: 40px solid transparent; border-right: 40px solid transparent; border-top: 40px solid #1e824c; }
			}
		}
		#selling-points { padding: 90px 0 200px 0; background: #f8f8f8;
			#client-reviews {
				@include media('<desktop') { padding: 0 80px; }
				@include media('<laptop') { padding: 0 30px; }
				@include media('<tablet') { padding: 0 30px; }
				header {
					@include media('<laptop') { text-align: center; }
					h2 { text-transform: uppercase; }
				}
				.reviews { padding: 20px 0;
					.review-item { padding: 20px 0;
						[class*='col-'] { vertical-align: middle; }
						&:nth-child(even) { direction: rtl;
							.image-container { padding-left: 25px;
								@include media('<laptop') { padding-left: 0; }
							}
							.content { padding: 10px 50px 10px 30px;
								@include media('<laptop') { padding: 10px 40px; }
							}
							.arrow { margin: 0; font-size: 0; width: 0; height: 0; border-top: 60px solid transparent; border-bottom: 60px solid transparent; border-left: 54px solid #fff; }
						}
						&:nth-child(odd) {
							.image-container { padding-right: 25px;
								@include media('<laptop') { padding-right: 0; }
							}
							.content { padding: 10px 30px 10px 50px;
								@include media('<laptop') { padding: 10px 40px; }
							}
							.arrow { margin: 0; font-size: 0;  width: 0; height: 0; border-top: 60px solid transparent; border-bottom: 60px solid transparent; border-right: 54px solid #fff; }
						}
						.arrow {
							@include media('<laptop') { display: none; }
						}
						.imagery {
							@include media('<laptop') { text-align: center; margin-bottom: 20px; }
							img { width: 100%;
								@include media('<laptop') { width: 50%; }
							}
						}
						.content { min-height: 120px; background: #fff;
							@include media('<laptop') {
								min-height: 0;
							}
							@include media('<tablet') {
								min-height: 0;
							}
							p { font-size: 16px; }
						}
					}
				}
			}
			#usp-list {
				header {
					@include media('<laptop') { text-align: center; }
				}
				.usps { padding: 40px 0;
					.content {

						ul { margin: 0;
							li { padding-left: 25px; }
						}
					}
				}
				.imagery {
					img { width: 100%; height: 100%; }
				}
			}
		}
		#about-consultant { margin-top: -80px;
			#consultant {
				.container { padding: 90px 0; background: #4daf7c;
					@include media('<laptop') { display: table; padding: 80px 180px; }
					@include media('<tablet') { padding: 80px 80px; }
					.content-container {
						@include media('<laptop') { display: table-footer-group; }
						.content {
							@include media('<laptop') {margin-top: 50px; text-align: center;}
							h2 { margin: 0; line-height: 30px; font-size: 28px; color: #fff; }
							h3 { margin-top: 0; font-size: 20px; color: #fff; }
							p { font-size: 16px; color: #fff;
								.button { float: right; margin-top: 70px; padding: 20px 80px; border: 0; color: #4daf7c;
									@include media('<laptop') { float: none !important; margin-top: 30px !important; padding: 20px 50px !important; width: auto; text-align: center; }
									&:hover { transition: 250ms ease-in-out; background: #1e824c; color: #fff; }
								}
							}
						}
					}
					.image-container {
						@include media('<laptop') { display: table-header-group; }
						// @include media('<laptop') { position: absolute; display: block; top: 60px; left: 0; right: 0; margin: 0 auto; }
						.imagery {
							.circle { margin: 0 auto; height: 300px; width: 300px; background: transparent center center no-repeat; background-size: cover; border-radius: 100%;
								@include media('<laptop') { width: 200px; height: 200px; }
							}
						}
					}
				}
			}
		}
		#services-cta { padding: 75px 0;
			#cta {
				.content {
					h2 { text-transform: uppercase; }
					p { font-size: 16px;
						.button { float: right; margin-top: 50px; background: #1e824c; color: #fff;
							@include media('<tablet') {
								width: 100%;
								padding: 20px 0;
								text-align: center;
							}
							&:hover { background: #fff; color: #1e824c; border: 1px solid #1e824c; }
						}
					}
				}
			}
		}
		.image-banner { height: 500px; background: transparent center center no-repeat; background-size: cover; }
		#contact-form { padding: 60px 0 30px 0;
			#contact-information {
				.content {
					h2 {
						font-weight: 500;
						font-family: 'Helvetica', Arial, sans-serif;
					}
					ul, li, a {
						font-weight: 500;
						font-family: 'Helvetica', Arial, sans-serif;
					}
				}
				@include media('<tablet') {
					display: table;
				}
				.content-container {
					@include media('<tablet') {
						display: table-footer-group;
					}
					.content {
						h2 {
							margin-top: 0;
						}
					}
				}
				.imagery-container {
					@include media('<tablet') {
						display: table-header-group;
					}
					.imagery {
						img {
							width: 100%;
						}
					}
				}
			}
		}
		#our-services {
			#services {
				.services-item { padding: 45px 0; border-bottom: 1px solid #ddd;
					[class*='col-'] { vertical-align: middle; }
					.content {
						h2 { margin-bottom: 5px; font-size: 22px; color: #7d0000; }
						p { margin-top: 5px; font-size: 16px; }
					}
					.imagery {
						img { width: 100%; }
					}
				}
				.contact-cta { text-align: right;
					p { margin: 45px 0;
						.button { text-align: center; background: #1e824c; color: #fff;
							&:hover { transition: 250ms ease-in-out; border: 1px solid #1e824c; background: #fff; color: #1e824c; }
						}
					}
				}
			}
		}
		#usp { padding: 60px 0 100px 0; background: #f8f8f8;
			#usps {
				.usp-item { padding: 0 60px 0 60px;
					.content {
						.number { position: relative; font-size: 24px;
							&:after { content: ''; position: absolute; display: inline-block; margin-left: 10px; top: 80%; width: 90%; height: 1px; background: #000; }
						}
						p { font-size: 24px; }
					}
				}
			}
		}
		#contact-cta { height: 350px; font-size: 0;
			@include media('<tablet') { height: auto; }
			.container {
				a {
					.overlay1,.overlay2 { position: relative; display: inline-block; width: 50%; height: 350px; transition: opacity 0.5s ease; -webkit-transition: opacity 0.5s ease; -moz-transition: opacity 0.5s ease;
						@include media('<tablet') { display: block; width: 100%; }
						&:after { content: '\A'; position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: rgba(0,0,0,0.6); opacity: 1; transition: opacity 0.5s; -webkit-transition: opacity 0.5s; -moz-transition: opacity 0.5s; }
						&:hover:after { opacity: 0.2; }
						&:hover {
							.content { background: rgba(30,130,76,0.5); }
						}
					}
					.imagery { position: absolute; width: 100%; height: 100%; top: 0; left: 0; background: transparent center center no-repeat; background-size: cover;
						.content { position: relative; display: inline-block; z-index: 100; padding: 0px 40px; top: 50%; margin-top: -50px;
							p { font-size: 24px; color: #fff; }
						}
					}
				}
			}
		}
		#consultant {
			#consultant-information {
				.container { position: relative; z-index: 100; margin-top: -150px; margin-bottom: 40px; padding: 50px 0 80px 0; background: #1e824c;
					@include media('<laptop') { margin: 0; }
					@include media('<tablet') { margin: 0; }
					header {
						@include media('<tablet') { padding-left: 8.33333%; padding-right: 8.33333%; }
						h2 { line-height: 30px; margin: 0; font-size: 28px; color: #fff; }
						p { margin: 0; font-size: 20px; color: #fff; }
					}
					@include media('<tablet') { display: table; }
					.content-container {
						@include media('<tablet') { display: table-footer-group; }
						.content {
							@include media('<tablet') { padding-left: 8.33333%; padding-right: 8.33333%; }
							h3 { font-size: 21px; color: #fff; }
							p { font-size: 16px; color: #fff; }
						}
					}
					.image-container {
						@include media('<tablet') { display: table-header-group; }
						.imagery { margin-top: 100px;
							@include media('<tablet') { margin-top: 20px; }
							.circle { margin: 0 auto; height: 300px; width: 300px; background: transparent center center no-repeat; background-size: cover; border-radius: 100%;
								@include media('<laptop') { height: 200px; width: 200px; }
								@include media('<tablet') { margin-bottom: 70px; }
							}
						}
					}
				}
			}
		}
		#base-content {
			padding: 40px 0;
		}
	}


/*	==========================================================================
		Footer
	========================================================================== */

	#footer { padding: 60px 0 125px 0; background: #1e824c;
		[class*='col-'] { vertical-align: middle; }
		.contact-details {
			@include media('<laptop') { padding: 10px 0; }
			ul { margin: 0; padding: 0; list-style: none;
				@include media('<laptop') { text-align: center; }
				li{ display: inline-block; margin: 0;
					a { display: inline-block; vertical-align: middle; line-height: 14px; color: #fff;
						i { display: inline-block; vertical-align: middle; margin-right: 12px; font-size: 20px; color: #fff; }
						span { display: inline-block; vertical-align: middle; }
					}
					&:after { content: ''; display: inline-block; vertical-align: middle; margin: 0 10px; width: 1px; height: 15px; background: #fff; }
					&:last-child {
						&:after { content: none; }
						a { border: 0; }
					}
				}
			}
		}
		nav { text-align: center;
			ul { margin: 0; padding: 0; list-style: none;
				.first { margin: 0; }
				li { display: inline-block; margin: 0 0 0 15px;
					a { color: #fff; }
				}
			}
		}
	}
